import {
  ConfirmationModal,
  Table,
  TableColumnConfig,
  Text,
  TextButton,
} from '@weave/design-system';
import { Page } from 'components/shared';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';
import { randomStringGenerator } from 'helpers/random-string-generator';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from 'redux/actions/location';
import React, { useEffect, useMemo, useState } from 'react';
import { InterpolationWithTheme, css } from '@emotion/core';
import { theme } from '@weave/theme';
import {
  RegisterPhoneNumberArgs,
  registerPhoneNumber,
  useLocationRegistrationStatusDetailsQuery,
  useLocationTCRBrandLockStatusQuery,
  useLocationTcrBrandQuery,
} from 'apis/tendlc';
import { RegistrationStatusIndicator } from './components/registration-status-indicators.component';
import {
  PhoneRegistrationStatus,
  PhoneRegistrationStatusDetails,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { RegisterButton } from './components/register-button.component';
import { startCase } from 'lodash';
import { setIsLoading } from 'components/outage-alerts/outage-alerts-details/outage-alerts-details-reducer';
import { useAlert } from '@weave/alert-system';
import { useQueryClient } from 'react-query';
import { GetLocationRegistrationStatusDetailsResponse } from '@weave/schema-gen-ts/dist/schemas/tendlc/coordinator/v1/coordinator_service.pb';

const TCR_REF_VERSION = 1;

export const TenDLCPage = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const queryClient = useQueryClient();
  const alert = useAlert();
  const [referenceId, setReferenceId] = useState<string>();
  const [showOnlyDefault, setShowOnlyDefault] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] =
    useState<RegisterPhoneNumberArgs>();
  const locationTcrBrandQuery = useLocationTcrBrandQuery();
  const locationTcrBrandLockedQuery = useLocationTCRBrandLockStatusQuery({
    brandId: locationTcrBrandQuery.data?.tcrBrand.brandId ?? '',
  });
  const {
    query: locationRegistrationStatusDetailsQuery,
    key: locationRegistrationStatusDetailsKey,
  } = useLocationRegistrationStatusDetailsQuery();
  // Adding `null` here due to a weird error of an unexpected `null` being returned from the BE
  const defaultPhoneData = locationRegistrationStatusDetailsQuery.data
    ?.defaultSmsStatusDetails as PhoneRegistrationStatusDetails | null;
  const defaultPhoneNumber = defaultPhoneData?.phoneNumber;
  const defaultSmsIsRegistered = defaultPhoneData?.registered;
  const colConfig = useMemo(
    (): TableColumnConfig<PhoneRegistrationStatusDetails>[] => [
      {
        id: 'default',
        Header: <Header title="Default" />,
        headerLabel: 'Default',
        accessor: (row) => (row.phoneNumber === defaultPhoneNumber ? 'Default' : 'Other'),
        cellRenderer: (value) => <Text size="medium">{value}</Text>,
        width: 100,
        cellAlign: 'center',
      },
      {
        id: 'phone-number',
        Header: <Header title="Phone Number" />,
        headerLabel: 'Phone Number',
        accessor: (row) => row.phoneNumber,
        cellRenderer: (value) => <Text size="medium">{value}</Text>,
        width: 120,
        cellAlign: 'center',
      },
      {
        id: 'registered',
        Header: <Header title="Registered" />,
        headerLabel: 'Registered',
        accessor: (row) => row.registered,
        cellRenderer: (value) => (
          <RegistrationStatusIndicator.Phone registered={value} hideTitle unstyled />
        ),
        width: 130,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'registration-status',
        Header: <Header title="Registration Status" />,
        headerLabel: 'Registration Status',
        accessor: (row): string =>
          row.status.replace('PHONE_REGISTRATION_STATUS_', '').replace(/_/g, ' '),
        cellRenderer: (value: string) => (
          <Text size="small" textAlign="center">
            {startCase(value.toLowerCase())}
          </Text>
        ),
        width: 130,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'registration-details',
        Header: <Header title="Registration Details" />,
        headerLabel: 'Registration Details',
        accessor: (row) => row.details,
        cellRenderer: (value) => (
          <Text size="small" textAlign="left">
            {value || 'N/A'}
          </Text>
        ),
        width: 250,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'registration-status-timestamp',
        Header: <Header title="Registration Timestamp" />,
        headerLabel: 'Registration Timestamp',
        accessor: (row) =>
          row.statusDate ? new Date(row.statusDate).toLocaleString() : 'None',
        cellRenderer: (value) => (
          <Text size="small" textAlign="center">
            {value}
          </Text>
        ),
        width: 130,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'provider',
        Header: <Header title="Provider" />,
        headerLabel: 'Provider',
        accessor: (row) => row.cnp.replace('TCR_CNP_', '').replace(/_/g, ' '),
        cellRenderer: (value) => (
          <Text size="small" textAlign="center">
            {value}
          </Text>
        ),
        width: 120,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'campaign-id',
        Header: <Header title="Campaign ID" />,
        headerLabel: 'Campaign ID',
        accessor: (row) => row.campaignId || 'None',
        cellRenderer: (value) =>
          value === 'None' ? (
            <Text size="small">{value}</Text>
          ) : (
            <CopyToClipboardButton copyText={value}>
              <Text
                css={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                size="small"
                textAlign="center"
              >
                {value}
              </Text>
            </CopyToClipboardButton>
          ),
        width: 150,
        cellAlign: 'center',
        sortType: 'string',
      },
      {
        id: 'begin-registration',
        Header: <Header title="Actions" />,
        sticky: 'right',
        headerLabel: 'Actions',
        resizable: false,
        accessor: (row) => row,
        cellRenderer: (row: PhoneRegistrationStatusDetails) => (
          <RegisterButton
            phoneRegistration={row}
            onClick={async () => {
              setSelectedPhoneNumber({
                phoneNumber: row.phoneNumber,
                locationId: row.locationId,
                cnp: row.cnp,
              });

              if (row.phoneNumber === defaultPhoneNumber) {
                await handleRegistration({
                  phoneNumber: row.phoneNumber,
                  locationId: row.locationId,
                  cnp: row.cnp,
                });
              } else {
                setShowConfirmationModal(true);
              }
            }}
          />
        ),
        cellAlign: 'center',
        maxWidth: 130,
        minWidth: 130,
        filterable: false,
        sortable: false,
      },
    ],
    [defaultPhoneNumber, locationRegistrationStatusDetailsQuery.data]
  );

  useEffect(() => {
    if (locationRegistrationStatusDetailsQuery.data && !defaultPhoneData) {
      setShowOnlyDefault(false);
      alert.warning({
        message: 'This location has no default SMS number',
      });
    }
  }, [locationRegistrationStatusDetailsQuery.data]);

  useEffect(() => {
    if (locationId) {
      setReferenceId(
        `${TCR_REF_VERSION}:${locationId}:${randomStringGenerator({
          length: 4,
        })}`
      );
    }
  }, [locationId]);

  const isLoading =
    locationRegistrationStatusDetailsQuery.isLoading ||
    !locationRegistrationStatusDetailsQuery.isFetched ||
    locationTcrBrandQuery.isLoading ||
    locationTcrBrandLockedQuery.isLoading;

  if (isLoading) {
    return <Page title="10DLC" loading />;
  } else if (
    !locationRegistrationStatusDetailsQuery.data ||
    locationRegistrationStatusDetailsQuery.isError
  ) {
    return (
      <Page title="10DLC">
        <Text>
          There was an error fetching this location's phone registration details.
        </Text>
      </Page>
    );
  }

  const showRegistrationResultAlert = (
    responseDetails: PhoneRegistrationStatusDetails
  ) => {
    const { status, phoneNumber, details } = responseDetails;
    if (status === PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_REGISTERED) {
      alert.success({
        message: `Phone number ${phoneNumber} registered successfully!`,
      });
    } else if (
      status === PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_PENDING ||
      status === PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_IN_PROGRESS
    ) {
      alert.warning({
        message: `Phone number ${phoneNumber} registration is pending. Please check back later.`,
      });
    } else {
      const friendlyStatus = startCase(
        status.replace('PHONE_REGISTRATION_STATUS_', '').replace(/_/g, ' ').toLowerCase()
      );
      alert.error({
        message: `Phone number ${phoneNumber} registration failed with status: ${friendlyStatus}.${
          details ? ` The following details were provided: ${details}` : ''
        }`,
      });
    }
  };

  const handleRegistration = async (request: RegisterPhoneNumberArgs) => {
    try {
      const newRow = await registerPhoneNumber(request);
      queryClient.setQueryData<GetLocationRegistrationStatusDetailsResponse>(
        locationRegistrationStatusDetailsKey,
        (oldData) => {
          if (!oldData) {
            return {
              defaultSmsStatusDetails: {
                ...newRow.statusDetails,
              },
              allStatusDetails: [newRow.statusDetails],
            };
          }

          const isDefaultNumber = newRow.statusDetails.phoneNumber === defaultPhoneNumber;
          return {
            defaultSmsStatusDetails: isDefaultNumber
              ? newRow.statusDetails
              : oldData.defaultSmsStatusDetails,
            allStatusDetails: oldData.allStatusDetails.map((row) =>
              row.phoneNumber === newRow.statusDetails.phoneNumber
                ? newRow.statusDetails
                : row
            ),
          };
        }
      );
      showRegistrationResultAlert(newRow.statusDetails);
      await locationTcrBrandQuery.refetch();
    } catch {
      alert.error({
        message:
          'Something went wrong while registering the phone number. Please try again later.',
      });
    }
  };

  const handleCloseModal = () => {
    setSelectedPhoneNumber(undefined);
    setShowConfirmationModal(false);
    setIsLoading(false);
  };

  const { allStatusDetails } = locationRegistrationStatusDetailsQuery.data;
  const rows =
    showOnlyDefault && !!defaultPhoneData
      ? [defaultPhoneData]
      : allStatusDetails.sort((a) => (a.phoneNumber === defaultPhoneNumber ? -1 : 0));

  return (
    <Page
      title="10DLC"
      subtitle={
        <div css={registrationStatusIndicatorsStyles.wrapper}>
          {locationTcrBrandQuery.data && (
            <RegistrationStatusIndicator.Brand
              locationTcrBrand={locationTcrBrandQuery.data}
              locked={!!locationTcrBrandLockedQuery.data?.locked}
            />
          )}
          <RegistrationStatusIndicator.Phone registered={!!defaultSmsIsRegistered} />
        </div>
      }
      headerActions={
        !!referenceId && (
          <div css={referenceIdStyles.wrapper}>
            <CopyToClipboardButton copyText={referenceId}>
              <></>
            </CopyToClipboardButton>
            <div css={referenceIdStyles.text}>
              <Text weight="bold">TCR Reference ID</Text>
              <Text size="medium">{referenceId}</Text>
            </div>
          </div>
        )
      }
    >
      {allStatusDetails.length > 0 && (
        <>
          <Table
            tableInstanceId="tendlc-phone-registration-data-table"
            data={rows}
            colConfig={colConfig}
            // There's a weird type issue that will throw a false type error no matter what if `isPaginated` is not explicitly set to true
            isPaginated={!showOnlyDefault as true}
            hasGlobalSearch
            hasFilterColumns
            styleConfig={{
              rows: (rowData) =>
                css([
                  {
                    padding: theme.spacing(1),
                    p: {
                      margin: 0,
                      lineHeight: 1,
                    },
                  },
                  rowData.phoneNumber === defaultPhoneNumber && {
                    backgroundColor: theme.colors.primary5,
                  },
                ]),
            }}
            clientPaginationConfig={{
              showNumRowSelector: !showOnlyDefault,
            }}
            hasResizeColumns
            wrapperStyle={css({
              display: 'block',
              // Calculate the maximum width of the page to avoid overflow horizontally
              maxWidth: `calc(100vw - 320px - ${theme.spacing(10)} - ${theme.spacing(
                4
              )})`,
            })}
          />
          {!!defaultPhoneData && (
            <span css={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
              <TextButton onClick={() => setShowOnlyDefault((prev) => !prev)}>
                {showOnlyDefault
                  ? 'Show non-default numbers'
                  : 'Hide non-default numbers'}
              </TextButton>
            </span>
          )}
        </>
      )}
      <ConfirmationModal
        title="Are you sure?"
        message="Registering any number other than the default SMS number may result in an unneccessary charge to Weave."
        show={showConfirmationModal && !!selectedPhoneNumber}
        onClose={() => handleCloseModal()}
        onConfirm={async () => {
          if (selectedPhoneNumber) {
            await handleRegistration(selectedPhoneNumber);
            handleCloseModal();
          } else {
            alert.error({ message: 'Something went wrong. Please try again' });
          }
        }}
      />
    </Page>
  );
};

const referenceIdStyles: Record<string, InterpolationWithTheme<any>> = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    p: { margin: 0 },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    width: 315,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as const;

const registrationStatusIndicatorsStyles: Record<string, InterpolationWithTheme<any>> = {
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
  },
} as const;

type HeaderProps = {
  title: string;
};

const Header = ({ title }: HeaderProps) => (
  <Text size="medium" css={{ whiteSpace: 'normal', margin: 0 }}>
    {title}
  </Text>
);
