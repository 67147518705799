import { Page } from 'components/shared';
import {
  FormRow,
  ListField,
  PrimaryButton,
  useAlert,
  useForm,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import { CustomAxios } from 'redux/axios';

export const BlockUsersPage = () => {
  const alerts = useAlert();

  const { formProps, getFieldProps, values, reset, isComplete } = useForm({
    fields: {
      ip: { type: 'list', required: true },
      jti: { type: 'list', required: true },
      userId: { type: 'list', required: true },
      userName: {
        type: 'list',
        required: true,
      },
    },
  });

  const handleSubmit = async () => {
    try {
      await CustomAxios.post('/auth-api/v3/users/reset', values);
      reset();
      alerts.info('User Blocked Successfully');
    } catch (error) {
      console.log(error);
      alerts.error('Failed to Block User');
    }
  };

  return (
    <Page title="Block User">
      <form
        {...formProps}
        css={(theme: WeaveTheme) =>
          css`
            padding-top: ${theme.spacing(1)};
          `
        }
      >
        <FormRow>
          <ListField label="Add User Ids" {...getFieldProps('userId')} fieldType="text" />
        </FormRow>
        <FormRow>
          <ListField
            label="Add User Emails"
            helperText="Note: Only 3 email addressees may be added."
            {...getFieldProps('userName')}
            fieldType="email"
          />
        </FormRow>
        <FormRow>
          <ListField label="Add IP Address" {...getFieldProps('ip')} fieldType="text" />
        </FormRow>
        <FormRow>
          <ListField label="Add Tokens" {...getFieldProps('jti')} fieldType="text" />
        </FormRow>
      </form>
      <PrimaryButton onClick={handleSubmit} disabled={!isComplete}>
        Submit
      </PrimaryButton>
    </Page>
  );
};
