import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/core';
import { AlertsProviderWithRedux } from '@weave/alert-system';
import { OriginalThemeProvider } from '@weave/design-system';
import { ConnectedRouter } from 'connected-react-router';
import { LocationUpdater } from './components/location/location-updater/location-updater.component';
import { history } from './redux/store/history';
import { WeaveThemeProvider } from '@weave/platform-ui-components';
import { KeystrokesContainer as Keystrokes } from './components/keystrokes-container/keystrokes.container';
import { store } from './redux/store/store';
import { unregister } from './registerServiceWorker';
import { client } from './redux/store/apollo-client';
import { AppRouter } from './routers/app-router/app-router.component';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './providers/auth.provider';
import { AuthConfig } from './config/auth';
import { OktaAuth } from '@okta/okta-auth-js';
import { WebAuth } from 'auth0-js';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DebugModeProvider } from './components/debug/debug-provider';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/styles.css';

// This fixes the warnings about server-side rendering when using :first-child, :last-child, :nth-child
// This app is not server side rendered, so we manually turn it off globally.
// REF: https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
const emotionCache = createCache();
emotionCache.compat = true;

export type OktaAuthProps = {
  oktaAuth: OktaAuth;
  googleAuth: WebAuth;
};

const oktaAuth = new OktaAuth({ ...AuthConfig.Okta });
const googleAuth = new WebAuth({ ...AuthConfig.Google });

const App = () => {
  console.log('Version: ', process.env.REACT_APP_VERSION);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        //start with conservative 60s stale time
        staleTime: 60 * 1000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <CacheProvider value={emotionCache}>
      {/* Note: Older WeaveThemeProvider is required until all platform-ui-components are removed */}
      <WeaveThemeProvider>
        <OriginalThemeProvider includeEmotionTheme>
          <DebugModeProvider>
            <Provider store={store}>
              <ApolloProvider client={client}>
                <QueryClientProvider client={queryClient}>
                  {process.env.NODE_ENV === 'development' && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                  <Keystrokes>
                    <ConnectedRouter history={history}>
                      <AlertsProviderWithRedux>
                        <LocationUpdater>
                          <AuthProvider googleAuth={googleAuth} oktaAuth={oktaAuth}>
                            <AppRouter />
                          </AuthProvider>
                        </LocationUpdater>
                      </AlertsProviderWithRedux>
                    </ConnectedRouter>
                  </Keystrokes>
                </QueryClientProvider>
              </ApolloProvider>
            </Provider>
          </DebugModeProvider>
        </OriginalThemeProvider>
      </WeaveThemeProvider>
    </CacheProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// For now, we are opting out of using the service worker
// We could change this for production later if we want the improved performance
unregister();
