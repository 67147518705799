import { isObject } from 'lodash';

type GenericObject = { [key: string]: string | number | boolean | undefined };

function objectToQuery(obj: GenericObject): string {
  return Object.entries(obj).reduce(
    (result, [key, value], i) => `${result}${i === 0 ? '?' : '&'}${key}=${value}`,
    ''
  );
}

/**
 * Util for using path constants that have :param values with dynamic elements.
 * Only necessary for paths used in `Link`, `a` or programmatic routing (eg `history.push`).
 * You do not need this for `Route` paths.
 */
export function injectParams(
  path: string,
  params: GenericObject,
  query?: GenericObject | string
): string {
  let startingPath = path;
  if (isObject(query)) {
    startingPath += objectToQuery(query);
  } else if (typeof query === 'string') {
    startingPath += '?' + query.replace(/^\?/, '');
  }
  const output = Object.entries(params).reduce(
    (str, [key, value]) => str.replace(`:${key}`, `${value}`),
    startingPath
  );
  if (/:/g.test(output)) {
    const missedParams = output.match(/(:\w*)/g);
    const missedCount = missedParams!.length;
    const missedStr = missedParams!.join(', ').replace(/:/g, '');

    console.error(
      `injectParams: param${missedCount === 1 ? '' : 's'} ${missedStr} ${
        missedCount === 1 ? 'was' : 'were'
      } not provided. Paths will not work as expected if all params are not replaced.`
    );
  }
  return output;
}

export const PRIVATE_PATH = '/location/:id';
export const FEATURE_FLAGS = '/feature-flags';
export const OUTAGE_ALERTS = '/outage-alerts';
export const USER_LOOKUP = '/user-lookup';
export const CSM_DASHBOARD = '/csm-dashboard';
export const PROVISION_LOCATIONS = '/provision-locations/:slug?';
export const BLOCK_USER = '/block-user';

export const ANALYTICS = {
  root: `${PRIVATE_PATH}/analytics`,
  settings: `${PRIVATE_PATH}/analytics/settings`,
  roiScorecard: `${PRIVATE_PATH}/analytics/roi-scorecard`,
  onboarding: `${PRIVATE_PATH}/analytics/onboarding`,
};

export const HISTORY = {
  root: `${PRIVATE_PATH}/history`,
  historyList: `${PRIVATE_PATH}/history/:title`,
  patients: `${PRIVATE_PATH}/history/patients`,
  patientDetails: `${PRIVATE_PATH}/history/patient-details`,
  groupMsgDetails: `${PRIVATE_PATH}/history/group-msg-details`,
  appointmentHistory: `${PRIVATE_PATH}/history/appointment-history`,
  emailHistory: `${PRIVATE_PATH}/history/email-history`,
  autoMsgQueue: `${PRIVATE_PATH}/history/auto-msg-queue`,
  callRecords: `${PRIVATE_PATH}/history/call-records`,
  smsHistory: `${PRIVATE_PATH}/history/sms-history`,
  smsDetails: `${PRIVATE_PATH}/history/sms-details`,
  logs: `${PRIVATE_PATH}/history/logs`,
  recallEvents: `${PRIVATE_PATH}/history/recall-events`,
  orderEvents: `${PRIVATE_PATH}/history/order-events`,
  reviewsList: `${PRIVATE_PATH}/history/reviews-list`,
  appointmentConfirmationWritebacks: `${PRIVATE_PATH}/history/appointment-confirmation-writebacks`,
  syncAppWritebacks: `${PRIVATE_PATH}/history/sync-app-writebacks`,
  invoices: `${PRIVATE_PATH}/history/invoices`,
  clientLocations: `${PRIVATE_PATH}/history/client-locations`,
};

export const PHONES = {
  root: `${PRIVATE_PATH}/phones`,
  officeHours: `${PRIVATE_PATH}/phones/office-hours`,
  officeHoursLegacy: `${PRIVATE_PATH}/phones/office-hours-legacy`,
  department: `${PRIVATE_PATH}/phones/departments/:departmentId`,
  devices: `${PRIVATE_PATH}/phones/devices/`,
  device: `${PRIVATE_PATH}/phones/devices/:deviceId`,
  callGroups: `${PRIVATE_PATH}/phones/call-groups`,
  callGroup: `${PRIVATE_PATH}/phones/call-groups/:callGroupId`,
  callSettings: `${PRIVATE_PATH}/phones/call-settings`,
  faxSettings: `${PRIVATE_PATH}/phones/fax-settings`,
  orderNewNmber: `${PRIVATE_PATH}/phones/twilio/order-new-number`,
  callBlocking: `${PRIVATE_PATH}/phones/call-blocking`,
  voiceMailboxes: `${PRIVATE_PATH}/phones/voice-mailboxes`,
  mediaLibrary: `${PRIVATE_PATH}/phones/media-library`,
  phoneTree: `${PRIVATE_PATH}/phones/phone-tree`,
  voicemailOverride: `${PRIVATE_PATH}/phones/voicemail-override`,
  holdMusic: `${PRIVATE_PATH}/phones/hold-music`,
  tenantMigration: `${PRIVATE_PATH}/phones/tenant-migration`,
  deunification: `${PRIVATE_PATH}/phones/deunify`,
  userMobileSupport: `${PRIVATE_PATH}/phones/users/:userId/user-mobile-support`,
};

export const SETTINGS = {
  root: `${PRIVATE_PATH}/settings`,
  autoReminders: `${PRIVATE_PATH}/settings/auto-reminders`,
  emailMarketing: `${PRIVATE_PATH}/settings/email-marketing`,
  featureFlags: `${PRIVATE_PATH}/settings/feature-flags`,
  locations: `${PRIVATE_PATH}/settings/location`,
  manualTemplates: `${PRIVATE_PATH}/settings/manual-templates`,
  onlineScheduling: `${PRIVATE_PATH}/settings/online-scheduling`,
  payments: `${PRIVATE_PATH}/settings/payments`,
  reviews: `${PRIVATE_PATH}/settings/reviews`,
  statusMapping: `${PRIVATE_PATH}/settings/status-mapping`,
  billing: `${PRIVATE_PATH}/settings/billing`,
  tendlc: `${PRIVATE_PATH}/settings/tendlc`,
};

export const SYNC_APP = {
  syncApps: `${PRIVATE_PATH}/sync-apps`,
  syncAppSettings: `${PRIVATE_PATH}/sync-app-settings`,
  multiSyncAppSettings: `${PRIVATE_PATH}/multi-sync-app-settings`,
};

export const OTHER = {
  customization: `${PRIVATE_PATH}/customization`,
  dashboard: `${PRIVATE_PATH}/dashboard`,
  desktopClients: `${PRIVATE_PATH}/desktop-clients`,
  onboardingAutomation: `${PRIVATE_PATH}/onboarding-automation`,
  syncApp: `${PRIVATE_PATH}/sync-app`,
  users: `${PRIVATE_PATH}/employees/users`,
  autoMessageDubugger: `${PRIVATE_PATH}/debug`,
  userConfig: '/user-config',
  auditLogsGlobal: `/audits`,
  DesktopReleases: '/desktop-releases',
  media: `${PRIVATE_PATH}/media/:mediaId`,
  auditLogs: `${PRIVATE_PATH}/audit-logs`,
  portabilityCheck: `${PRIVATE_PATH}/portability-check`,
  e911Dashboard: `/e911Dashboard`,
};

export const ONBOARDING = {
  root: `${PRIVATE_PATH}/onboarding`,
  handoff: `${PRIVATE_PATH}/onboarding/handoff`,
  onboarding: `${PRIVATE_PATH}/onboarding/onboarding`,
  intakeForm: `${PRIVATE_PATH}/onboarding/intake-form`,
  porting: `${PRIVATE_PATH}/onboarding/porting-v1`,
  portingV2: `${PRIVATE_PATH}/onboarding/porting-v2`,
  createHandoff: `${PRIVATE_PATH}/onboarding/handoff/create`,
  handoffHubInfo: `${PRIVATE_PATH}/onboarding/handoff/:snapshotId`,
};

export const LOCATION = {
  create: '/location/create',
  dev: '/location/dev',
  newcustomer: '/location/newcustomer',
  demo: '/location/new/demo',
};
