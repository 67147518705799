import React, { useRef, useState } from 'react';
import {
  FormRow,
  Modal,
  SwitchField,
  TextareaField,
  useForm,
  Text,
  Accordion,
  CheckboxField,
  TextProps,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { showError, showSuccess } from '@weave/alert-system';
import { CustomAxios } from '../../redux/axios';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';

interface Props {
  flagName: string;
  close: Function;
  defaultValue?: boolean;
}

type AccordionItems =
  | 'set-percentage'
  | 'set-custom-list'
  | 'set-all'
  | 'set-default-value'
  | '';

const Enabled = (props: TextProps) => (
  <Text as="span" color="success" size="small" {...props}>
    enabled
  </Text>
);
const Disabled = (props: TextProps) => (
  <Text as="span" color="error" size="small" {...props}>
    disabled
  </Text>
);
const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

export const BatchSetFlagModal = (props: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [percentage, setPercentage] = useState(0);
  const initDefaultValue = useRef(props.defaultValue || false);
  const [openAccordionItem, setOpenAccordionItem] = useState<AccordionItems>('');
  const { getFieldProps, formProps, values, reset } = useForm({
    allowInvalidSubmission: true,
    fields: {
      locationIDs: { type: 'text', required: true, value: '' },
      newValue: { type: 'switch', value: initDefaultValue.current },
      allLocations: { type: 'switch', value: false },
      defaultValue: { type: 'switch', value: initDefaultValue.current },
      confirmFfChange: { type: 'checkbox', value: false, required: true },
    },
    onSubmit: async function () {
      const { flagName } = props;
      const { newValue, defaultValue, locationIDs, allLocations } = values;
      if (initDefaultValue.current !== defaultValue) {
        await updateFfDefaultValue({ flagName, defaultValue });
      }
      if (allLocations) {
        await updateFfForAllLocations({ flagName, newValue });
      }
      if (percentage > 0) {
        await updateFfByPercentage({ flagName, newValue, percentage });
      }
      if (locationIDs) {
        await updateFfByLocationIdList({
          flagName,
          newValue,
          locationIDs: locationIDs,
        });
      }
      props.close();
      queryClient.invalidateQueries(['feature-flags']);
    },
  });
  const addedLocations = values.locationIDs ? values.locationIDs.split('\n').length : 0;
  const changedDefaultValue = initDefaultValue.current !== values.defaultValue;

  async function updateFfDefaultValue({
    flagName,
    defaultValue,
  }: {
    flagName: string;
    defaultValue?: boolean;
  }) {
    return CustomAxios.patch(`/feature-flags/v1/flags/${flagName}`, {
      default_value: defaultValue,
    })
      .then((res) => {
        dispatch(showSuccess('Flag updated!'));
      })
      .catch((err) => {
        dispatch(showError('Error updating feature flag'));
      });
  }
  async function updateFfForAllLocations({
    flagName,
    newValue,
  }: {
    flagName: string;
    newValue?: boolean;
  }) {
    return CustomAxios.post('/feature-flags/v1/locations/flags:all', {
      flagName: flagName,
      value: newValue,
    })
      .then((res) => {
        dispatch(showSuccess('All locations set'));
      })
      .catch((err) => {
        dispatch(showError('Failed to set all locations'));
      });
  }
  async function updateFfByPercentage({
    flagName,
    percentage,
    newValue,
  }: {
    flagName: string;
    percentage: number;
    newValue?: boolean;
  }) {
    return CustomAxios.post('/feature-flags/v1/locations/flags:percentage', {
      flagName: flagName,
      value: newValue,
      percentage: percentage,
    })
      .then((res) => {
        dispatch(showSuccess('Locations Set!'));
      })
      .catch((err) => {
        dispatch(showError('Error setting flag for locations'));
      });
  }
  async function updateFfByLocationIdList({
    flagName,
    locationIDs,
    newValue,
  }: {
    flagName: string;
    locationIDs: string;
    newValue?: boolean;
  }) {
    return CustomAxios.post('/feature-flags/v1/locations/flags:batchCreate', {
      flagName: flagName,
      locationIds: locationIDs?.split('\n').filter((value) => uuidRegex.test(value)),
      value: newValue,
    })
      .then((res) => {
        dispatch(showSuccess('Locations Set!'));
      })
      .catch((err) => {
        dispatch(showError('Error setting flag for locations'));
      });
  }

  return (
    <Modal show css={{ width: '700px !important' }} onClose={() => props.close()}>
      <Modal.Header css={{ paddingBottom: theme.spacing(2) }}>
        Batch Set <code>{props.flagName}</code>
      </Modal.Header>
      <Modal.Body>
        <Accordion
          variant="bordered"
          shouldRenderBody
          showBoxShadow
          onChange={(item) => {
            setOpenAccordionItem(item as AccordionItems);
            // every time the accordion changes, reset the changes the user made
            // this process is intentionally a little bit friction-ful
            setPercentage(0);
            reset();
          }}
        >
          <Accordion.Item value="set-custom-list">
            <Accordion.Header
              title="Set custom list"
              subtitle="Provides the control of toggling the ff value for a list of hand picked locations."
            />
            <Accordion.Body css={{ borderBottom: `1px solid ${theme.colors.gray300}` }}>
              <form css={{ padding: theme.spacing(1) }}>
                <TextareaField
                  height={250}
                  css={{ width: '100%' }}
                  label="Place 1 location ID per line"
                  placeholder="Ex. 00000000-0000-0000-0000-000000000000"
                  {...getFieldProps('locationIDs')}
                />
              </form>
              <Text css={{ margin: 0 }}>Total Locations Entered: {addedLocations}</Text>
              <FormRow
                css={{
                  marginTop: theme.spacing(1),
                  display: 'flex',
                  justifyContent: 'flex-end ',
                }}
              >
                <SwitchField {...getFieldProps('newValue')} label="Enabled" />
              </FormRow>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item value="set-percentage">
            <Accordion.Header
              title="Set custom percentage"
              subtitle="Provides the ease of toggling the ff value for a percentage of locations we select for you."
            />
            <Accordion.Body>
              <label htmlFor="customRange1" className="form-label">
                Percentage of all locations {percentage}%
              </label>
              {/* use native html input and react state because design system doesn't have slider */}
              <input
                type="range"
                value={percentage}
                className="form-range"
                id="customRange1"
                onChange={(e) => setPercentage(e.target.valueAsNumber)}
              />
              <FormRow
                css={{
                  marginTop: theme.spacing(1),
                  display: 'flex',
                  justifyContent: 'flex-end ',
                }}
              >
                <SwitchField {...getFieldProps('newValue')} label="Enabled" />
              </FormRow>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item value="set-all">
            <Accordion.Header
              title="Update all values"
              subtitle="Set all ~30k Weave locations in one go."
            />
            <Accordion.Body
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Text color="light" size="small" css={{ margin: 0 }}>
                  FF is currently {initDefaultValue.current ? <Enabled /> : <Disabled />}{' '}
                  by default.
                </Text>
                {initDefaultValue.current === values.newValue ? null : (
                  <div>
                    <Text size="small" css={{ margin: theme.spacing(1, 0, 0) }}>
                      This action will
                    </Text>
                    <ol>
                      <Text as="li" size="small">
                        Set 30k weave locations to{' '}
                        {values.newValue ? 'enabled' : 'disabled'}
                      </Text>
                      <Text as="li" size="small">
                        Change the default value for all locations added in the future to{' '}
                        {values.newValue ? 'enabled' : 'disabled'}
                      </Text>
                    </ol>
                  </div>
                )}
              </div>
              <SwitchField
                {...getFieldProps('newValue')}
                onChange={(e) => {
                  getFieldProps('newValue').onChange(e);
                  getFieldProps('allLocations').onChange({
                    name: 'allLocations',
                    value: true,
                  });
                }}
                label="Enabled"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item value="set-default-value">
            <Accordion.Header
              title="Set default value"
              subtitle="Sets the default value for any location that is not currently set."
            />
            <Accordion.Body
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Text color="light" size="small" css={{ margin: 0 }}>
                FF is currently {initDefaultValue.current ? <Enabled /> : <Disabled />} by
                default.
                <br />
                {changedDefaultValue ? (
                  <>
                    <Text size="small" color="light" as="span" css={{ margin: 0 }}>
                      You're setting the default value to{' '}
                    </Text>
                    {!initDefaultValue.current ? <Enabled /> : <Disabled />}.
                  </>
                ) : null}
              </Text>
              <SwitchField {...getFieldProps('defaultValue')} label="Enabled" />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div css={{ margin: theme.spacing(2) }}>
          {openAccordionItem === 'set-percentage' && percentage > 0 ? (
            <CheckboxField
              {...getFieldProps('confirmFfChange')}
              label={
                <Text>
                  Yes, I want to turn <code>{props.flagName}</code>{' '}
                  <b>{values.newValue ? 'on' : 'off'}</b> for <b>{percentage}%</b> of
                  Weave locations.
                </Text>
              }
            />
          ) : null}
          {openAccordionItem === 'set-custom-list' && addedLocations ? (
            <CheckboxField
              {...getFieldProps('confirmFfChange')}
              label={
                <Text>
                  Yes, I want to turn <code>{props.flagName}</code>{' '}
                  <b>{values.newValue ? 'on' : 'off'}</b> for <b>{addedLocations}</b>{' '}
                  locations.
                </Text>
              }
            />
          ) : null}
          {openAccordionItem === 'set-default-value' && changedDefaultValue ? (
            <CheckboxField
              {...getFieldProps('confirmFfChange')}
              label={
                <Text>
                  Yes, I want to change the default value of <code>{props.flagName}</code>{' '}
                  to <b>{values.defaultValue ? 'on' : 'off'}</b> for ~30k locations.
                </Text>
              }
            />
          ) : null}
          {openAccordionItem === 'set-all' &&
          initDefaultValue.current !== values.newValue ? (
            <CheckboxField
              {...getFieldProps('confirmFfChange')}
              label={
                <Text>
                  Yes, I want to turn <b>{values.newValue ? 'on' : 'off'}</b>{' '}
                  <code>{props.flagName}</code> for ~30k locations.
                </Text>
              }
            />
          ) : null}
        </div>
        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={formProps.onSubmit}
          onSecondaryClick={() => props.close()}
          disablePrimary={!values.confirmFfChange}
          primaryLabel="Set"
          secondaryLabel="Cancel"
        />
      </Modal.Body>
    </Modal>
  );
};
