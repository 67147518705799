import { CustomAxios } from 'redux/axios';
import { StripeReader } from '../terminal-list.component';
import { useQuery, UseQueryOptions } from 'react-query';

type FetchStripeReadersProps = {
  paymentsUrl?: string;
  stripeLocationId?: string;
};

export const fetchStripeReaders = ({
  paymentsUrl,
  stripeLocationId,
}: FetchStripeReadersProps) => {
  if (!stripeLocationId || !paymentsUrl) {
    return Promise.resolve([]);
  }

  return new Promise<StripeReader[]>((resolve) => {
    // Stripe Terminal js sdk
    const terminal = (window as any).StripeTerminal.create({
      onFetchConnectionToken: async () => {
        const response = await CustomAxios.post(
          `${paymentsUrl}/v1/terminal/connection-token`,
          {
            stripeId: stripeLocationId,
          }
        );
        return response.data.data.secret;
      },
      onUnexpectedReaderDisconnect: () => {
        console.error('connection to Stripe Terminal lost');
        resolve([]);
      },
    });

    const config = { simulated: false, location: stripeLocationId };
    terminal.discoverReaders(config).then(
      ({ discoveredReaders }) => {
        resolve(discoveredReaders || []);
      },
      () => {
        resolve([]);
      }
    );
  });
};

export const useFetchStripeReadersQuery = (
  request: FetchStripeReadersProps,
  options?: UseQueryOptions<StripeReader[]>
) => {
  return useQuery({
    queryKey: [
      'payments',
      'stripeReaders',
      request.stripeLocationId,
      request.paymentsUrl,
    ],
    queryFn: () => fetchStripeReaders(request),
    retry: false,
    ...options,
    enabled:
      !!request.stripeLocationId && !!request.paymentsUrl && (options?.enabled ?? true),
  });
};
