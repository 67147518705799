import { HandoffTypeMetric } from '../handoff.constants';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';
import { TaskIds, TaskStatus } from '../../../../../../onboarding.types';

const PAYMENTS_PASS_VALUE = 'Active';
const PAYMENTS_STRIPE_SETUP_VALUE = 'Active';

// PAYMENTS STRIPE ACCOUNT METRIC TEST //
export const paymentsStripeAccountMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Stripe account set up`;
  },
  dependencyDataKeys: [DependencyDataKeys.LocationTasks],
  expectedValue: PAYMENTS_STRIPE_SETUP_VALUE,
  actualValueFn: (dependentData): string => {
    const locationTasksQueryResponse = dependentData[DependencyDataKeys.LocationTasks];
    const err = locationTasksQueryResponse?.error;
    if (err) return err.message;

    const modules = locationTasksQueryResponse?.data?.modules;

    if (!modules) return 'No onboarding modules data found';

    const allTasks = modules.map((module) => module.tasks).flat();

    const stripeTask = allTasks?.find((task) => task.id === TaskIds.stripeSetup);

    if (!stripeTask) return 'No stripe task data found';

    const hasStripePaymentsSetup = stripeTask.status === TaskStatus.Complete;

    if (hasStripePaymentsSetup) return PAYMENTS_STRIPE_SETUP_VALUE;

    const stripePaymentStatus = stripeTask.status;

    return stripePaymentStatus!;
  },
  resultFn: function (dependentData) {
    const locationTasksQueryResponse = dependentData[DependencyDataKeys.LocationTasks];

    const err = locationTasksQueryResponse?.error;
    if (err) return Result.Fail;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Issue with stripe account setup process',
    'Setup in process',
    'Stripe setup unsuccessful',
    'Has another Payments provider',
    'Wasn’t interested in sending one to a customer or themselves',
  ],
};

// PAYMENTS TERMINAL METRIC //
export const fullPaymentsWithTerminalMetric: HandoffTypeMetric = {
  testCriteriaFn: () => {
    return 'Payments terminal is plugged in and registering';
  },
  dependencyDataKeys: [DependencyDataKeys.PaymentsSettings],
  expectedValue: PAYMENTS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const paymentsSettingsData = dependentData[DependencyDataKeys.PaymentsSettings];

    const err = paymentsSettingsData?.error;

    // payments team uses the 404 to show an office has never had payments
    // or has deactivated it
    if (err && err.response?.status === 404) {
      return 'INCOMPLETE';
    } else if (err && err.response?.status !== 404) {
      return err.message;
    }

    const paymentsSettings = paymentsSettingsData?.data;

    if (!paymentsSettings?.merchantData) return 'No Data';

    const hasPaymentsTerminal = paymentsSettings?.merchantData?.hasTerminalLocation;
    const isTerminalOnline = paymentsSettings?.stripeReaders?.some(
      ({ status }) => status === 'online'
    );

    if (hasPaymentsTerminal) {
      return isTerminalOnline ? PAYMENTS_PASS_VALUE : 'Terminal is Offline';
    }

    return 'Inactive';
  },
  resultFn: function (dependentData) {
    const paymentsSettings = dependentData[DependencyDataKeys.PaymentsSettings];

    const err = paymentsSettings?.error;
    if (err && err.response?.status === 404) {
      return Result.Fail;
    } else if (err && err.response?.status !== 404) {
      return Result.Error;
    }

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Office has not plugged in terminal yet, but knows how',
    `Office's IT helping with terminal installation`,
    'Couldn’t get terminals to work',
    'Terminal not shipped in time',
    'Scheduled with PSS to install',
    "Didn't want to install at this time",
  ],
};
