import React from 'react';
import { css } from '@emotion/core';
import { history } from '../../../redux/store/history';
import {
  OTHER,
  FEATURE_FLAGS,
  OUTAGE_ALERTS,
  USER_LOOKUP,
  PROVISION_LOCATIONS,
  injectParams,
  BLOCK_USER,
} from '../../../constants/paths';
import ProfileIcon from '../../../images/wv-icon-profile.svg';
import { WeaveTheme } from '@weave/theme-original';
import {
  LogOutIcon,
  UpdatesIcon,
  useFlyoutMenu,
  UserIcon,
  WarningBadgeIcon,
  CheckIcon,
  FormsIcon,
  SearchIcon,
  PhoneCallIcon,
  PortingStatusIcon,
  LocationIcon,
  BlockIcon,
} from '@weave/design-system';
import { useAuth } from '../../../providers/auth.provider';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../redux/actions/auth/auth.selectors';

export const ProfileInfo = () => {
  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { handleSignOut } = useAuth();

  const handleOutageAlerts = () => {
    history.push(OUTAGE_ALERTS);
  };

  const gotoPage = (page: string) => {
    history.push(page);
  };

  const gotoUserConfig = () => {
    gotoPage(OTHER.userConfig);
  };
  const gotoAudits = () => {
    gotoPage(OTHER.auditLogsGlobal);
  };
  const gotoDesktopReleases = () => {
    gotoPage(OTHER.DesktopReleases);
  };
  const goToPortabilityCheck = () => {
    gotoPage(OTHER.portabilityCheck);
  };
  const gottoE911Dashboard = () => {
    gotoPage(OTHER.e911Dashboard);
  };

  const actions = [
    {
      Icon: UserIcon,
      label: 'User Config',
      onClick: gotoUserConfig,
    },
    {
      Icon: CheckIcon,
      label: 'Feature Flags',
      onClick: () => {
        gotoPage(FEATURE_FLAGS);
      },
    },
    {
      Icon: PhoneCallIcon,
      label: 'E911 dashbaord',
      onClick: gottoE911Dashboard,
    },
    {
      Icon: PortingStatusIcon,
      label: 'Portability Check',
      onClick: goToPortabilityCheck,
    },
    {
      Icon: UpdatesIcon,
      label: 'Desktop Releases',
      onClick: gotoDesktopReleases,
    },
    {
      Icon: WarningBadgeIcon,
      label: 'Outage Alerts',
      onClick: handleOutageAlerts,
    },
    {
      Icon: FormsIcon,
      label: 'Audit Logs',
      onClick: gotoAudits,
    },
    {
      Icon: SearchIcon,
      label: 'User Look Up',
      onClick: () => {
        gotoPage(USER_LOOKUP);
      },
    },
    {
      Icon: LocationIcon,
      label: 'Provision Locations',
      onClick: () => {
        gotoPage(
          injectParams(PROVISION_LOCATIONS, {
            slug: '',
          })
        );
      },
      disabled: true,
    },
    {
      Icon: BlockIcon,
      label: 'Block Users',
      onClick: () => {
        gotoPage(BLOCK_USER);
      },
    },
    {
      Icon: LogOutIcon,
      label: 'Sign Out',
      onClick: handleSignOut,
    },
  ];

  return (
    <div style={{ display: isAuthenticated ? 'flex' : 'none' }}>
      <button
        css={css`
          &:focus,
          &:active {
            outline: none;
          }
        `}
        data-testid={'profile-btn'}
        {...triggerProps}
      >
        <img alt="" src={ProfileIcon} />
      </button>
      {active && (
        <FlyoutMenu
          {...flyoutProps}
          actions={actions}
          css={(theme: WeaveTheme) =>
            css`
              box-shadow: ${theme.shadows.heavy};
              min-width: 200px;
              right: 0;
              top: 100%;
            `
          }
        />
      )}
    </div>
  );
};
